import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SparkleDialogService } from '@sparkle-ui/core';
import { Observable, map } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { WelcomeDialogComponent } from '../dialogs/welcome-dialog/welcome-dialog.component';
import { Me } from '../openapi';

export const authedGuard: CanActivateFn = (route, state) => {
  const authState = inject(AuthState);

  if (!authState.$isLoggedIn()) {
    return authState.refreshIsLoggedIn();
  }

  return authState.$isLoggedIn();
};

let welcomeDialogRef: WelcomeDialogComponent | null = null;

export const welcomeGuard: CanActivateFn = (route, state) => {
  const authState = inject(AuthState);
  const dialog = inject(SparkleDialogService);

  return (
    authState.fetchUser({
      returnSubscription: true,
    }) as Observable<Me>
  ).pipe(
    map((res) => {
      if (res?.contractApprovals?.length === 0 && !welcomeDialogRef) {
        welcomeDialogRef = dialog.open(WelcomeDialogComponent, {
          width: '500px',
          closeOnEsc: false,
          closeOnOutsideClick: false,
        });
      }

      return true;
    })
  );
};

export const notLoggedInGuard: CanActivateFn = (route, state) => {
  const authState = inject(AuthState);
  const router = inject(Router);

  if (authState.$isLoggedIn()) {
    return true;
  }

  if (authState.$expiresAt() && (authState.$expiresAt() as number) < Date.now()) {
    authState.logout();
    return true;
  }

  if (authState.$expiresAt() && (authState.$expiresAt() as number) > Date.now()) {
    authState.refreshIsLoggedIn().subscribe(() => {
      if (authState.$isLoggedIn()) {
        router.navigate(['/app']);
      }
    });
  }

  return true;
};
