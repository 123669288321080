export const environment = {
  production: false,
  baseUrl: 'https://dev-api.duplicati.com',
  //baseUrl: 'https://localhost:5001',
  ingressUrl: 'https://dev-ingress.duplicati.com/backupreports',
  machineServerUrl: 'wss://dev-gateway.api.duplicati.com/portal',
  clientAppUrl: 'https://client.dev-app.duplicati.com',
  email: {
    authUrl: "https://duplicatib2c.b2clogin.com/duplicatib2c.onmicrosoft.com/b2c_1_signupsignin/oauth2/v2.0/authorize",
    clientId: "2353d4fd-abf2-4786-8822-d373bf6aaa98",
    redirectPath:"/auth/email",
    scopeArr:['openid'],
  },
  github: {
    authUrl: 'https://github.com/login/oauth/authorize',
    clientId: 'Iv1.9f811a64754ad195',
    redirectPath: '/auth/github',
    scopeArr: ['read:user', 'user:email'],
    allowSignup: 'true',
  },
  google: {
    authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    clientId: '241155532614-qii3ri2mc7s2ofq5i1dol6912q6j0gqb.apps.googleusercontent.com',
    redirectPath: '/auth/google',
    scopeArr: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'],
    responseType: 'code',
    accessType: 'offline',
    prompt: 'consent',
  },
  apple: {
    authUrl: 'https://appleid.apple.com/auth/authorize',
    clientId: 'com.duplicati.console.dev',
    redirectPath: '/auth/apple',
    scopeArr: ['email'],
    responseType: 'code',
    responseMode:'form_post'
  },
};
