import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SparkleAlertService } from '@sparkle-ui/core';
import { catchError, finalize, Observable, shareReplay, switchMap, throwError } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { ENVIRONMENT_TOKEN } from 'src/environments/environment-token';
import { Me, Organization } from '../openapi';

let refreshRequest: Observable<[Me, Organization[]]> | null = null;

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthState);
  const env = inject(ENVIRONMENT_TOKEN);
  const sparkleAlertService = inject(SparkleAlertService);

  let _req = req;

  if (auth.$token() && req.url.startsWith(env.baseUrl)) {
    _req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${auth.$token()}`),
    });
  }

  return next(_req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 && !refreshRequest) {
        refreshRequest = auth.refreshToken().pipe(shareReplay());
      }

      if (error.status === 401) {
        return refreshRequest!.pipe(
          switchMap(() => {
            return next(
              req.clone({
                headers: req.headers.set('Authorization', `Bearer ${auth.$token()}`),
              })
            );
          }),
          catchError((err) => {
            if (err.status === 401 || err.status === 403) auth.logout();

            return throwError(() => err);
          }),
          finalize(() => (refreshRequest = null))
        );
      } else {
        sparkleAlertService.error(error.message);
      }

      const errorMsg = `Error Code: ${error.status}, Message: ${error.message}`;

      return throwError(() => {
        return {
          error,
          message: errorMsg,
        };
      });
    })
  );
};
