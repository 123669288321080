import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ENVIRONMENT_TOKEN } from 'src/environments/environment-token';
import { OpenAPI } from './core/openapi';
import { LOCALSTORAGE } from './core/services/localstorage.token';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  template: `
    <router-outlet />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  #env = inject(ENVIRONMENT_TOKEN);
  #ls = inject(LOCALSTORAGE);

  ngOnInit() {
    OpenAPI.BASE = this.#env.baseUrl;

    this.#ls.clearAllNotCurrentVersion();
  }
}
