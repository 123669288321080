<form [formGroup]="form" (submit)="submit()">
  <header header>
    <h3 class="title-20">Welcome to duplicati</h3>
  </header>

  <div content>
    <div class="row">
      <spk-form-field>
        <label>First name</label>
        <input type="text" name="name" formControlName="name" />
      </spk-form-field>

      <spk-form-field>
        <label>Last name</label>
        <input type="text" name="lastName" formControlName="lastName" />
      </spk-form-field>
    </div>

    <spk-form-field [attr.readonly]="true">
      <label>E-mail</label>
      <input type="email" name="email" formControlName="email" />
    </spk-form-field>

    <div class="approvals">
      <spk-checkbox class="raised primary">
        I agree to the
        <a href="https://duplicati.com/termsofservice" target="_blank">Terms of Service</a>
        and
        <a href="https://duplicati.com/privacypolicy" target="_blank">Privacy Policy</a>

        <input type="checkbox" formControlName="tosAndPolicy" />
      </spk-checkbox>

      <spk-checkbox class="raised primary">
        I agree to receive marketing emails
        <input type="checkbox" formControlName="marketingConsent" />
      </spk-checkbox>
    </div>

    @if (!form.value.tosAndPolicy) {
      <spk-alert type="question">
        Make sure to accept terms of service and privacy policy before you continue.
      </spk-alert>
    }
  </div>

  <footer footer>
    <button
      spk-button
      class="raised primary"
      type="submit"
      [class.loading]="isLoading()"
      [disabled]="!form.valid || isLoading()">
      Save
    </button>
  </footer>
</form>
